import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { NotificationService } from './services/notification.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { UserService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { PhonePipe } from './pipes/phone.pipe';
import { PhoneInputPipe } from './pipes/phoneInput.pipe';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzIconModule,
        PerfectScrollbarModule,
        SearchPipe,
        PhonePipe,
        PhoneInputPipe,
        NgxMaskModule
    ],
    imports: [
        RouterModule,
        CommonModule,
        NzIconModule,
        NzToolTipModule,
        PerfectScrollbarModule,
        NgxMaskModule.forRoot(options)
    ],
    declarations: [
        SearchPipe,
        PhonePipe,
        PhoneInputPipe
    ],
    providers: [
        ThemeConstantService,
        NotificationService,
        NzNotificationService,
        NzModalService,
        UserService,
        AuthenticationService
    ]
})

export class SharedModule { }
