import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class UserService {

    constructor(private http: HttpClient, private auth: AuthenticationService) {

     }

     create(data){
        return this.http.post<any>(`${environment.baseUrl}/auth/signup`, 
        { ...data }, 
        { headers: { token: `${this.auth.currentUserValue.token}`} })
        .pipe(map(result => {
            if (result.data) {
               return result.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getUserById(id: number) {
        return this.http.get<any>(`${environment.baseUrl}/users/${id}`, {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(user => {
            if (user.data && user.data.id) {
                localStorage.setItem('currentUser', JSON.stringify(user.data));
               return user.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getLogsByUserLogged() {
        return this.http.get<any>(`${environment.baseUrl}/internal/users/${this.auth.currentUserValue.id}/logs`, {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(user => {
            if (user.data) {
               return user.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getUserByIdAdmin(id: number) {
        return this.http.get<any>(`${environment.baseUrl}/users/${id}`, {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(user => {
            if (user.data && user.data.id) {
               return user.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getUserNotificationAdmin(id: number) {
        return this.http.get<any>(`${environment.baseUrl}/administration/users/${id}/notifications`, {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(notification => {
            return notification.data;
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getUserAlertsAdmin(id: number) {
        return this.http.get<any>(`${environment.baseUrl}/administration/users/${id}/alerts`, {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(alerts => {
            return alerts.data;
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    changePassword(password, current_password) {
        return this.http.put<any>(`${environment.baseUrl}/internal/users/${this.auth.currentUserValue.id}/password`, 
        {password, current_password},
        {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(user => {
            if (user.data && user.data.id) {
               return user.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getDoctorPatients(id: any = null) {
        return this.http.get<any>(`${environment.baseUrl}/doctor/patients/${id ? id: 0}`, 
        {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(patients => {
            if (patients.data) {
                return patients.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    getDoctorsByOrganization() {
        return this.http.get<any>(`${environment.baseUrl}/organization/doctors`, {
            headers: { token: `${this.auth.currentUserValue.token}`}
        })
        .pipe(map(doctors => {
            if (doctors.data) {
                return doctors.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    
  getAllUsers() {
    return this.http
      .get<any>(
        `${environment.baseUrl}/internal/users`,
        {
          headers: { token: `${this.auth.currentUserValue.token}` },
        })
      .pipe(
        map((users) => {
          if (users.data) {
            return users.data;
          }
        })
      )
      .toPromise()
      .catch((err) => {
        throw new Error(err.error.error);
      });
  }
}