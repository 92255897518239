import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';
import { ThemeConstantService } from '../../services/theme-constant.service';
import * as moment from 'moment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent{

    searchVisible : boolean = false;
    quickViewVisible : boolean = false;
    isFolded : boolean;
    isExpand : boolean;
    public visible = false;
    public currentUser: any;
    public notifications: any;
    public unread: number;

    constructor(private router: Router, private modal: NzModalService, private notification: NotificationService, private themeService: ThemeConstantService, private auth: AuthenticationService) {
        this.currentUser = this.auth.getLoggedUser();
    }

    

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    }

    convertDate(date){
       return moment(date).fromNow();
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }
  
    close(): void {
      this.visible = false;
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    logout(): void {
        this.auth.logout();
        this.router.navigateByUrl("/authentication/login");
    }
}
