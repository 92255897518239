import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
  {
    path: '/dashboard/home',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
  },
  {
    path: '',
    title: 'Programs',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'snippets',
    submenu: [
      {
        path: '/programs/list',
        title: 'All Programs',
        iconType: 'nzIcon',
        icon: 'unordered-list',
        iconTheme: 'outline',
        submenu: [],
      },
      {
        path: '/programs/create',
        title: 'Create Program',
        iconType: 'nzIcon',
        icon: 'plus-square',
        iconTheme: 'outline',
        submenu: [],
      }
    ],
  },
  {
    path: '/fellows/home',
    title: 'Fellows',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-switch',
    submenu: [],
  },
  {
    path: '/fmigs-board/home',
    title: 'FMIGS Board',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'edit',
    submenu: [],
  },
  {
    path: '/committee/home',
    title: 'Committees',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'key',
    submenu: [],
  },
  {
    path: '/administrator/home',
    title: 'System Administrator',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-switch',
    submenu: [],
  },
  {
    path: '/files/home',
    title: 'Files Management',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'snippets',
    submenu: [],
  },
  {
    path: '/reports/home',
    title: 'Reports',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'snippets',
    submenu: [],
  },
  {
    path: '/logs/home',
    title: 'System Logs',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'info-circle',
    submenu: [],
  },
  /* {
    path: '',
    title: 'Notifications',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-switch',
    submenu: [
      {
        path: '/notifications/create',
        title: 'Create',
        iconType: 'nzIcon',
        icon: 'edit',
        iconTheme: 'outline',
        submenu: [],
      },
      {
        path: '/notifications/list',
        title: 'List',
        iconType: 'nzIcon',
        icon: 'key',
        iconTheme: 'outline',
        submenu: [],
      }
    ],
  } */
];