import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotificationService {
  constructor(
    private http: HttpClient,
    private notification: NzNotificationService,
    private auth: AuthenticationService
  ) {}

  createNotification(type: string, title: string, message: string): void {
    this.notification.create(type, title, message);
  }

  getAllNotifications() {
    return this.http
      .get<any>(`${environment.baseUrl}/users/notifications/all`, {
        headers: { token: `${this.auth.currentUserValue.token}` },
      })
      .pipe(
        map((notification) => {
          if (notification.data) {
            return notification.data;
          }
        })
      )
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  markAlertAsRead(id) {
    return this.http
      .put<any>(`${environment.baseUrl}/users/alerts/${id}`, {
        headers: { token: `${this.auth.currentUserValue.token}` },
      })
      .pipe(
        map((alert) => {
          if (alert.data) {
            return alert.data;
          }
        })
      )
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  getAllAlerts() {
    return this.http
      .get<any>(`${environment.baseUrl}/users/alerts/all`, {
        headers: { token: `${this.auth.currentUserValue.token}` },
      })
      .pipe(
        map((notification) => {
          if (notification.data) {
            return notification.data;
          }
        })
      )
      .toPromise()
      .catch((err) => {
        throw new Error(err.error.error);
      });
  }

  updateAlert(data: any) {
    return this.http.post<any>(`${environment.baseUrl}/users/alerts`, { ...data },
    {
      headers: { token: `${this.auth.currentUserValue.token}` },
    })
    .toPromise().then(success => {
        return success;
        
    }).catch(err => {
        throw new Error(err.error.error);
    });
}
}
