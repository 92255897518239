<div class="header">
    <div class="logo logo-dark">
        <a href="">
           <!--  <img src="https://static.wixstatic.com/media/98b13c_cf5b90433ab84e2681ebdf34d93bc716~mv2.png/v1/crop/x_0,y_0,w_283,h_34/fill/w_396,h_48,al_c,lg_1,q_85/logo_top.webp" width="200px" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo"> -->
            <span><b></b></span><b style="color: rgb(17, 29, 58)">AAGL FMIGS</b>
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
            <!-- <li>
                <a (click)="searchToggle()">
                    <i nz-icon nzType="search" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="380" nzTitle="Search" [nzVisible]="searchVisible" nzPlacement="left" (nzOnClose)="searchToggle()">
                    <app-search></app-search>
                </nz-drawer>
            </li> -->
        </ul>
        <ul class="nav-right">
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar nzSize="large" nzText="{{this.currentUser?.first_name.charAt(0)}}{{this.currentUser?.last_name.charAt(0)}}" style="background-color:rgb(17, 29, 58);"></nz-avatar>

                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">Hi, {{this.currentUser?.first_name}} {{this.currentUser?.last_name}}</p>
                                    <p class="m-b-0 opacity-07">{{this.currentUser?.email}}</p>
                                </div>
                            </div>
                        </li>
                        <div *ngIf="(this.currentUser.system_role !== 5 && this.currentUser.system_role !== 6)">
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" [routerLink]="['/profile/edit']">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">My Profile</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </div>
                    <li nz-menu-item>
                        <a class="p-v-5 d-flex align-items-center justify-content-between" [routerLink]="['/profile/password']">
                            <div>
                                <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                                <span class="m-l-10">Change Password</span>
                            </div>
                            <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a class="p-v-5 d-flex align-items-center justify-content-between" [routerLink]="['/logs/home']">
                            <div>
                                <i class="opacity-04 font-size-16" nz-icon nzType="info-circle" theme="outline"></i>
                                <span class="m-l-10">Logs</span>
                            </div>
                            <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                        </a>
                    </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
            <li>
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="480" nzTitle="Session Information" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <app-quick-view></app-quick-view>
                </nz-drawer>
            </li>
        </ul>
    </div>
</div>    