import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthenticationService, private route: Router) { }

  canActivate() {
    return this.auth.getLoggedUser() ? true : this.route.navigate(['/authentication/login']);  
  }
} 