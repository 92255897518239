
<h5>Last IP connected</h5>
<p>{{this.currentSession?.ip}}</p>
<nz-divider></nz-divider>
<h5>Platform connected</h5>
<p>{{this.currentSession?.platform}}</p>
<nz-divider></nz-divider>
<h5>Device</h5>
<p>{{this.currentSession?.device}}</p>
<nz-divider></nz-divider>
<h5>Browser</h5>
<p>{{this.currentSession?.browser}}</p>
<nz-divider></nz-divider>
<h5>Time connected</h5>
<p>{{convertDate(this.currentSession?.created_on)}}</p>
