import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';

@Component({
    selector: 'app-quick-view',
    templateUrl: './quick-view.component.html'
})



export class QuickViewComponent {
  
    selectedHeaderColor: string;
    isSideNavDark : boolean;
    isFolded : boolean;
    public currentSession: any;

    switchValue = false;
    loading = false;

    constructor( private themeService: ThemeConstantService, private auth: AuthenticationService) {
        this.currentSession = this.auth.getLastSession();
    }

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
        
    }

    changeHeaderColor() {
        this.themeService.changeHeaderColor(this.selectedHeaderColor)
    }

    convertDate(date){
        return moment(date).fromNow();
     }

    clickSwitch(): void {
        if (!this.loading) {
          this.loading = true;
          setTimeout(() => {
            this.switchValue = !this.switchValue;
            this.loading = false;
          }, 3000);
        }
      }

    toggleSideNavDark() {
        this.themeService.toogleSideNavDark(this.isSideNavDark);
    }

    toggleFold() {
        this.themeService.toggleFold(this.isFolded);
    }

}

