import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'profile',
        loadChildren: () => import('../../profile/profile.module').then(m => m.ProfileModule),
    },
    {
        path: 'programs',
        loadChildren: () => import('../../programs/program.module').then(m => m.ProgramModule),
    },
    {
        path: 'notifications',
        loadChildren: () => import('../../notifications/notifications.module').then(m => m.ListNotificationsModule),
    },
    {
        path: 'fmigs-board',
        loadChildren: () => import('../../fmigs-board/fmigs.module').then(m => m.FmigsBoardModule),
    },
    {
        path: 'committee',
        loadChildren: () => import('../../committee/committee.module').then(m => m.CommitteeModule),
    },
    {
        path: 'administrator',
        loadChildren: () => import('../../administrator/administrator.module').then(m => m.AdministratorModule),
    },
    {
        path: 'fellows',
        loadChildren: () => import('../../fellows/fellows.module').then(m => m.FellowModule),
    },
    {
        path: 'files',
        loadChildren: () => import('../../files/files.module').then(m => m.FilesModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule),
    },
    {
        path: 'logs',
        loadChildren: () => import('../../logs/logs.module').then(m => m.LogsModule),
    }
];