import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment'
import { User } from '../interfaces/user.type';
@Injectable()
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        const user = this.getLoggedUser();
        return user;
    }

    login(email: string, password: string, two_factor: string) {
        return this.http.post<any>(`${environment.baseUrl}/auth/signin`, { email, password, two_factor })
        .pipe(map(user => {
            if (user.data && user.data.token) {
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                localStorage.setItem('lastSession', JSON.stringify(user.last_login));
                this.currentUserSubject.next(user.data);
            }
            return user;
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    
    forgotPassword(email: string) {
        return this.http.post<any>(`${environment.baseUrl}/auth/forgot-password`, { email })
        .toPromise().then(success => {
            return success;
            
        }).catch(err => {
            throw new Error(err.error.error);
        });
    }

    getLoggedUser() : any {
        let user: any = localStorage.getItem('currentUser');
        user = user ? JSON.parse(user) : null;
        return user;
    }

    getLastSession() : any{
        let session: any = localStorage.getItem('lastSession');
        session = session ? JSON.parse(session) : null;
        return session;
    }

    createUserInvite(data) {
        return this.http.put<any>(`${environment.baseUrl}/invite/email`, { 
            ...data
         })
        .toPromise().then(success => {
            return success.data;
            
        }).catch(err => {
            throw new Error(err.error.error);
        });
    }

    getDoctorInvite(code: any) {
        return this.http.get<any>(`${environment.baseUrl}/invite/email?hash=${code}`)
        .pipe(map(invite => {
            if (invite.data) {
               return invite.data;
            }
        })).toPromise().catch(err => {
            throw new Error(err.error.error);
        });
    }

}