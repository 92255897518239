import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneInput',
})
export class PhoneInputPipe implements PipeTransform {
  transform(tel, args) {
    if(tel){
      var value = tel.toString().trim().replace(/^\+/, "");

      if (value.match(/[^0-9]/)) {
        return tel;
      }
  
      var country, city, number;
  
      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;
  
        case 11: // +CPPP####### -> CCC (PP) ###-####
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;
  
        case 13: // +CCCPP####### -> CCC (PP) ###-####
          country = 55;
          city = value.slice(2, 4);
          number = value.slice(4);
          break;
  
        default:
          return tel;
      }
  
      if (country == 1) {
        country = "+1";
        number = number.slice(0, 3) + "-" + number.slice(3);
      }
      if (country == 55) {
          country = "+55";
          number = number.slice(0, 5) + "-" + number.slice(5);
        }
      return (country + " (" + city + ") " + number).trim();
    }
    return null;
  }
}
